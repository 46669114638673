/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaskDto } from './TaskDto';
export type WorkPackageDto = {
    id: string;
    name: string;
    reference: number;
    description?: string;
    status: WorkPackageDto.status;
    tasks: Array<TaskDto>;
    allocatedPersonMonths: number;
    consumedPersonMonth?: number;
};
export namespace WorkPackageDto {
    export enum status {
        ACTIVE = 'ACTIVE',
        DELETED = 'DELETED',
        INACTIVE = 'INACTIVE',
    }
}


/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClockingDto } from './ClockingDto';
import type { UserDto } from './UserDto';
export type TaskDto = {
    id: string;
    title: string;
    reference: number;
    description: string;
    startDate: string;
    status: TaskDto.status;
    endDate: string;
    workPackageId: string;
    workers: Array<UserDto>;
    clockings: Array<ClockingDto>;
    consumedPersonMonth?: number;
};
export namespace TaskDto {
    export enum status {
        ACTIVE = 'ACTIVE',
        DELETED = 'DELETED',
        INACTIVE = 'INACTIVE',
    }
}


import { ReactNode } from 'react';
import { Button, Form, Modal } from 'antd';


interface ModalComponentProps<T> {
    open: boolean;
    name: string;
    onClose: () => void;
    onSubmit: (values: T) => void;
    initialValues?: T;
    children: ReactNode;
}


const ModalComponent = <T extends object>({ open, name, onClose, onSubmit, initialValues, children }: ModalComponentProps<T>) => {
    const [form] = Form.useForm<T>();

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                onSubmit(values);
                form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            title={name}
            open={open}
            onOk={handleOk}
            onCancel={onClose}
            style={{minWidth:520}}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" name="form_in_modal" initialValues={initialValues}>
                {children}
            </Form>
        </Modal>
    );
};

export default ModalComponent;

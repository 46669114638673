import { useModal } from 'shared/contexts/ModalContext';
import { UpdateTaskWorkersDto, UserDto, UpdateTaskDto, ProjectDto, TaskDto, WorkPackageDto, CreateTaskDto, ClockingDto } from 'shared/generated-sources';
import { useAddWorkersToTaskMutation, useDeleteWorkersFromTaskMutation, useUpdateTaskMutation, useDeleteTaskMutation, useAddTaskMutation, useLazyGetClockingQuery } from 'projects/api/ProjectApi';
import { TeamOutlined, UserAddOutlined, UserDeleteOutlined } from "@ant-design/icons";

import UpdateTaskModal from './modals/UpdateTaskModal';
import TableComponent from './TableComponent';
import AddWorkersOnTaskModal from './modals/AddWorkersOnTaskModal';
import RemoveWorkersOnTaskModal from './modals/RemoveWorkersOnTaskModal';
import { useEffect, useRef, useState } from 'react';
import GanttChartHeader from './GanttChartHeaderComponent';
import GanttChart from './GanttChartComponent';
import AddTaskModal from './modals/AddTaskModal';
import { useSelector } from 'react-redux';
import { getCurrentUserEmail } from 'store/store';
import { findTaskById, findWorkPackageById } from 'shared/functions/helper.function';

interface TasksComponentProps {
    project: ProjectDto | undefined;
    projectId: string;
    workpackageId: string;
    workpackageRef: number
}

const TasksComponent: React.FC<TasksComponentProps> = ({ project, projectId, workpackageId, workpackageRef }) => {

    const { showModal, hideModal } = useModal();
    const [addTask] = useAddTaskMutation();
    const [updateTask] = useUpdateTaskMutation();
    const [deleteTask] = useDeleteTaskMutation();
    const [addWorkers] = useAddWorkersToTaskMutation();
    const [removeWorkers] = useDeleteWorkersFromTaskMutation();

    const titleDivRef = useRef<HTMLDivElement | null>(null);
    const renderDivsRef = useRef<HTMLDivElement[]>([]);

    const [clockingPerTask, setClockingPerTask] = useState<Record<string, number>>({});


    const userEmail = useSelector(getCurrentUserEmail);
    const [triggerGetClocking, { data: clockingData, isLoading: isLoadingClocking, isSuccess: isSuccessClocking }] = useLazyGetClockingQuery();

    useEffect(() => {
        taskClocking();

        const titleDiv = titleDivRef.current;
        const renderDiv = renderDivsRef.current;

        if (!titleDiv || !renderDiv) return;
        const syncScroll = (source: HTMLDivElement, target: HTMLDivElement[]) => {
            target.forEach((div: HTMLDivElement) => div.scrollLeft = source.scrollLeft);
        };
        const handleScroll = () => {
            syncScroll(titleDiv, renderDiv);

        };
        titleDiv.addEventListener('scroll', handleScroll);

        return () => {
            titleDiv.removeEventListener('scroll', handleScroll);
        };

    }, [clockingData]);

    const TASKS_COLUMNS = [
        {
            title: "Ref",
            dataIndex: 'reference',
            key: 'reference',
            render: (reference: string) => `T${workpackageRef}.${reference}`,
        },
        {
            title: 'Clocked Hours',
            dataIndex: 'clockedHours',
            key: 'clockedHours',
            onCell: () => ({
                style: { width: '8vw' },
            }),
            render: (dataIndex: number, record: TaskDto) => `${clockingPerTask[record.id] ? clockingPerTask[record.id] : 0}`,
        },
        {
            title: "Tasks",
            dataIndex: 'title',
            key: 'title',
            onCell: () => ({
                style: {
                    maxWidth: '32vw',
                }
            }),
            render: (dataIndex: string, record: TaskDto) => (
                <>
                    <strong>{record.title}</strong> : {record.description}
                </>
            ),
        },
        {
            title: () => (
                <div
                    ref={titleDivRef}
                    style={{
                        width: "32vw",
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <GanttChartHeader
                        startDate={project ? project.startDate : ""}
                        endDate={project ? project.endDate : ""}
                    />
                </div>
            ),
            dataIndex: 'id',
            key: 'timeline',
            render: (record: string) => {
                const currentTask = findTaskById(project!,workpackageId, record);
                return (
                    <div
                        ref={el => el && renderDivsRef.current.push(el)}
                        style={{
                            width: "32vw",
                            overflowX: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <GanttChart
                            startDate={project ? project.startDate : ""}
                            endDate={project ? project.endDate : ""}
                            currentTaskId={currentTask!.id}
                            projectId={projectId}
                            workPackageId={workpackageId}
                        />

                    </div>
                )
            },
        },
    ]




    const handleAddTask = async (workPackageId: string, task: CreateTaskDto) => {

        const project = await addTask({ projectId: projectId, workPackageId, task });
    }

    const handleUpdateTask = async (workPackageId: string, taskId: string, task: UpdateTaskDto) => {
        const project = await updateTask({ projectId: projectId, workPackageId, taskId, task });
    }

    const handleDeleteTask = async (record: { id: string, workPackageId: string }) => {
        const project = await deleteTask({ projectId: projectId, workPackageId: record.workPackageId, taskId: record.id });
    }

    const handleAddWorkerToTask = async (record: { workPackageId: string, taskId: string, usersIds: UpdateTaskWorkersDto }) => {
        const project = await addWorkers({ projectId: projectId, workPackageId: record.workPackageId, taskId: record.taskId, usersIds: record.usersIds })
    }
    const handleRemoveWorkerToTask = async (record: { workPackageId: string, taskId: string, usersIds: UpdateTaskWorkersDto }) => {
        const project = await removeWorkers({ projectId: projectId, workPackageId: record.workPackageId, taskId: record.taskId, usersIds: record.usersIds })
    }

    const openAddTaskModal = (workpackageId: string) => {
        showModal(
            "addTaskModal",
            <AddTaskModal
                onClose={() => hideModal("addTaskModal")}
                onSubmit={(values) => {
                    handleAddTask(workpackageId, values).then(data => {
                        hideModal("addTaskModal")
                    })
                }}
                projectStartDate={project!?.startDate}
                projectEndDate={project!?.endDate}
            />
        );
    };

    const openUpdateTaskModal = (record: { id: string }) => {

        const editTask = findTaskById(project!,workpackageId, record.id)

        showModal(
            "updateTaskModal",
            <UpdateTaskModal
                onClose={() => hideModal("updateTaskModal")}
                onSubmit={(values) => {
                    handleUpdateTask(workpackageId, record.id, values).then(data => {
                        hideModal("updateTaskModal")
                    })
                }}
                projectStartDate={project!?.startDate}
                projectEndDate={project!?.endDate}
                initialValues={{
                    title: editTask ? editTask.title : '',
                    reference: editTask ? editTask.reference : 0,
                    description: editTask ? editTask.description : '',
                    startDate: editTask ? editTask.startDate : new Date().toDateString(),
                    endDate: editTask ? editTask.endDate : new Date().toDateString(),
                    status: editTask ? editTask.status : TaskDto.status.INACTIVE
                }}
            />
        );
    };
    const openViewWorkerOnTask = (record: { id: string, workers: UserDto[] }) => {
        console.log(record.workers)
    }
    const openAssignWorkerToTaskModal = (record: { id: string, workers: UserDto[] }) => {
        const workersIds = record.workers.map(worker => worker.id)
        showModal(
            "addWorkersToTaskModal",
            <AddWorkersOnTaskModal
                onClose={() => hideModal("addWorkersToTaskModal")}
                onSubmit={(values) => {
                    handleAddWorkerToTask({
                        workPackageId: workpackageId,
                        taskId: record.id,
                        usersIds: values
                    });
                    hideModal("addWorkersToTaskModal");
                }}
                users={project!.workers.filter(worker => !workersIds.includes(worker.id))}
            />
        );
    };

    const openDeleteWorkerFromTaskModal = (record: { id: string, workers: UserDto[] }) => {
        const workersIds = record.workers.map(worker => worker.id)
        showModal(
            "removeWorkersFromTaskModal",
            <RemoveWorkersOnTaskModal
                onClose={() => hideModal("removeWorkersFromTaskModal")}
                onSubmit={(values) => {
                    handleRemoveWorkerToTask({
                        workPackageId: workpackageId,
                        usersIds: values,
                        taskId: record.id,
                    });
                    hideModal("removeWorkersFromTaskModal");
                }}
                users={project!.workers.filter(worker => workersIds.includes(worker.id))}
            />
        );
    };

    const taskClocking = async () => {
        try {
            const currentWorkPackage = findWorkPackageById(project!,workpackageId)
            const response = await triggerGetClocking({
                clockingParams: {
                    taskIds: currentWorkPackage?.tasks.map((task: TaskDto) => task.id),
                },
            });

            if (response.data) {
                const newClockingPerTask: Record<string, number> = {};
                response.data.forEach((clocking: ClockingDto) => {
                    if (clocking.taskId)
                        newClockingPerTask[clocking.taskId] = (newClockingPerTask[clocking.taskId] || 0) + clocking.hoursWorked;
                });

                setClockingPerTask(newClockingPerTask);
            }
        } catch (error) {
            console.error("Error fetching clocking data:", error);
        }
        return 0;
    }
    return (

        <TableComponent
            pagination={{ current: 0, total: 0 }}
            data={project ? findWorkPackageById(project,workpackageId)?.tasks : []}
            columns={TASKS_COLUMNS}
            onAdd={() => openAddTaskModal(workpackageId)}
            tooltip="task"
            isWorkerViewing={userEmail !== project?.director.email}
            onEdit={openUpdateTaskModal}
            onDelete={handleDeleteTask}
            bonusActionButtons={(record) => ([
                {
                    text: "=",
                    tooltip: "See Worker on task",
                    icon: <TeamOutlined style={{ fontSize: '150%' }} />,
                    onBonusAction: () => openViewWorkerOnTask(record),
                },
                {
                    text: "+",
                    tooltip: "Add workers to task",
                    icon: <UserAddOutlined style={{ fontSize: '150%' }} />,
                    onBonusAction: () => openAssignWorkerToTaskModal(record),
                },
                {
                    text: "-",
                    tooltip: "Remove workers from task",
                    icon: <UserDeleteOutlined style={{ fontSize: '150%' }} />,
                    onBonusAction: () => openDeleteWorkerFromTaskModal(record)
                },
            ])}
        />
    )
}

export default TasksComponent

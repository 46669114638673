import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import AddClockingModal from "./modals/AddClockingModal";
import { ClockingDto, CreateClockingDto, TaskDto } from "shared/generated-sources";
import { useModal } from "shared/contexts/ModalContext";
import dayjs from "dayjs";
import { projectsApi, useAddClockingsMutation, useLazyGetClockingQuery } from "projects/api/ProjectApi";
import { useSelector } from 'react-redux';
import { RootState } from "store/store";
import { findTaskById, findWorkPackageById } from "shared/functions/helper.function";

interface GanttChartProps {
  startDate: string;
  endDate: string;
  currentTaskId: string | null;
  projectId: string
  workPackageId: string;
};

const GanttChart: React.FC<GanttChartProps> = ({
  startDate,
  endDate,
  currentTaskId,
  projectId,
  workPackageId
}) => {
  const project = useSelector((state: RootState) =>
    projectsApi.endpoints.getProject.select({ id: projectId })(state)?.data
  );
  const [task, setTask] = useState<TaskDto | undefined>(findTaskById(project!, workPackageId, currentTaskId!));

  const { showModal, hideModal } = useModal();
  const [addClocking] = useAddClockingsMutation();
  const [monthClockingReady, setMonthClockingReady] = useState(false);
  const [taskClockings, setTaskClockings] = useState<Record<string, number>>({});

  const [triggerGetClocking, { data: clockingData, isLoading: isLoadingClocking, isSuccess: isSuccessClocking }] = useLazyGetClockingQuery();

  const handleAddClockings = async (taskId: string, date: string, clocking: CreateClockingDto[]) => {
    getTaskClockings();
  }

  const openAddClockingModal = (currentTask: TaskDto, startDate: Date, endDate: Date) => {
    showModal(
      "addClockingModal",
      <AddClockingModal
        startDate={dayjs(startDate).startOf("day")}
        endDate={dayjs(endDate).endOf("day")}
        users={currentTask ? currentTask.workers : []}
        task={currentTask!}
        projectId={projectId}
        workPackageId={workPackageId}
        onClose={() => hideModal("addClockingModal")}
        onSubmit={(values, date) => {
          handleAddClockings(currentTask ? currentTask.id : '', date, values)
        }}
      />
    );
  };
  const svgRef = useRef<SVGSVGElement | null>(null);

  const getMonthsBetween = (start: Date, end: Date) => {
    const months = [];
    let date = new Date(start);
    while (date <= end || date.getMonth() < end.getMonth()) {
      months.push(new Date(date));
      date.setMonth(date.getMonth() + 1);
    }
    return months;
  };
  const getMonthDateRange = (month: Date, highlightStart: Date | null, highlightEnd: Date | null) => {
    const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
    const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    const startDate =
      highlightStart &&
        highlightStart.getMonth() === month.getMonth() &&
        highlightStart.getFullYear() === month.getFullYear()
        ? highlightStart
        : monthStart;

    const endDate =
      highlightEnd &&
        highlightEnd.getMonth() === month.getMonth() &&
        highlightEnd.getFullYear() === month.getFullYear()
        ? highlightEnd
        : monthEnd;
    return { startDate, endDate };
  };


  const [loadingClocking, setLoadingClocking] = useState(false);

  const isHighlightedMonth = (month: Date) => {
    const highlightStart = task?.startDate ? new Date(task.startDate) : null;
    const highlightEnd = task?.endDate ? new Date(task.endDate) : null;
    if (!highlightStart || !highlightEnd) return false;
    const monthStart = new Date(month.getFullYear(), month.getMonth(), 2);
    const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 1);
    return (
      highlightStart <= monthEnd && highlightEnd >= monthStart
    );
  };

  useEffect(() => {
    if (!svgRef.current) return;
    const currentTask = findTaskById(project!, workPackageId, currentTaskId!)!
    const months = getMonthsBetween(new Date(startDate), new Date(endDate));
    const monthWidth = 50;
    const totalWidth = months.length * monthWidth;
    const height = 50;
    getTaskClockings();

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    svg.attr("width", totalWidth);

    svg.selectAll(".month")
      .data(months)
      .enter()
      .append("rect")
      .attr("class", "month")
      .attr("x", (d, i) => i * monthWidth)
      .attr("y", 0)
      .attr("width", monthWidth)
      .attr("height", height)
      .attr("fill", (d) => (isHighlightedMonth(d) ? "#baece1" : "white"))
      .attr("stroke", "black")
      .on("mouseover", function (event, d) {
        d3.select(this).attr("fill", () => (isHighlightedMonth(d) ? "#00b197" : "white"));
      })
      .on("mouseout", function (event, d) {
        d3.select(this).attr("fill", (isHighlightedMonth(d) ? "#baece1" : "white"));
      })
      .on("click", (event, d) => {
        if (isHighlightedMonth(d)) {
          const { startDate, endDate } = getMonthDateRange(d, new Date(currentTask!.startDate), new Date(currentTask!.endDate));
          openAddClockingModal(currentTask, startDate, endDate);
        }
      });
    setTask(currentTask);

  }, [startDate, endDate, project]);

  useEffect(() => {
    if (!task || !monthClockingReady) return;
    const months = getMonthsBetween(new Date(startDate), new Date(endDate));
    const monthWidth = 50;
    const totalWidth = months.length * monthWidth;
    const height = 50;
    const svg = d3.select(svgRef.current);

    svg.selectAll(".label").remove();

    svg.selectAll(".label")
      .data(months)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d, i) => i * monthWidth + 2)
      .attr("y", height / 2)
      .style("font-size", "1rem")
      .style("pointer-events", "none")
      .each(function (d, i) {
        if (isHighlightedMonth(d)) {
          const formattedDate = d.toISOString().slice(0, 7);

          const clockingValue = taskClockings![formattedDate];
          if (clockingValue !== undefined) {
            d3.select(this)
              .append("tspan")
              .attr("x", i * monthWidth + 15)
              .attr("dy", "0.4rem")
              .text(`${clockingValue}`);
          }
        }
      });
  }, [task, monthClockingReady]);

  const getTaskClockings = async () => {
    try {

      const taskStartDate = new Date(task!.startDate).toISOString().split('T')[0];
      const taskEndDate = new Date(task!.endDate).toISOString().split('T')[0];

      const response = await triggerGetClocking({
        clockingParams: {
          taskIds: [task!.id],
          startDate: taskStartDate,
          endDate: taskEndDate,
        },
      });
      setMonthClockingReady(true);

      if (response.data) {
        const reducedClockings = response.data.reduce((acc: Record<string, number>, clocking: ClockingDto) => {
          const monthKey = clocking.date.slice(0, 7);
          acc[monthKey] = (acc[monthKey] || 0) + clocking.hoursWorked;
          return acc;
        }, {});
        setTaskClockings((prevMonthClocking) => ({
          ...prevMonthClocking,
          ...reducedClockings,
        }));
      }
    } catch (error) {
      console.error("Error fetching clocking data:", error);
      setMonthClockingReady(false);

    }
  };

  return (
    <svg ref={svgRef} height={50}></svg>
  );
};

export default GanttChart;

import { ProjectDto, TaskDto, WorkPackageDto } from "shared/generated-sources";

export function findTaskById(project: ProjectDto, workPackageId: string, taskId: string): TaskDto | undefined {

    return project?.workPackages.find(workPackage => workPackage.id === workPackageId)?.tasks.find(task => task.id === taskId);
}

export function findWorkPackageById(project: ProjectDto, workPackageId: string): WorkPackageDto {
    if (project && project.workPackages) {
        const foundWorkPackage = project.workPackages.find(workPackage => workPackage.id === workPackageId);
        if (foundWorkPackage) {
            return foundWorkPackage;
        }
    }

    throw new Error(`Work package with ID ${workPackageId} not found`);
}
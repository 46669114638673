import React from 'react';
import { Form, Input, Select } from 'antd';

import { UpdateProjectDto } from 'shared/generated-sources';

import ModalComponent from 'components/ModalComponent';

interface UpdateProjectModalProps {
    onSubmit: (values: UpdateProjectDto) => void;
    onClose: () => void;
    initialValues: UpdateProjectDto;
}

const UpdateProjectModal: React.FC<UpdateProjectModalProps> = ({ onSubmit, onClose, initialValues }) => {
    return (
        <ModalComponent<UpdateProjectDto>
            open
            name="Update Project"
            onClose={onClose}
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please input a name!' }]}
            >
                <Input type={"string"} />
            </Form.Item>
            <Form.Item

                name="allocatedPersonMonths"
                label="Allocated PM"
                rules={[{ required: true, message: 'Choose PM amount', type: 'number', transform: (value) => (value ? Number(value) : value), }]}
                normalize={(value) => (value ? Number(value) : value)}
            >
                <Input type={"number"} />
            </Form.Item>
            <Form.Item
                name="startDate"
                label="Start date"
                rules={[{ required: true, message: 'Choose a date', type: 'date' }]}
            >
                <Input type={"date"} />
            </Form.Item>
            <Form.Item
                name="endDate"
                label="End date"
                rules={[{ required: true, message: 'Choose a date', type: 'date' }]}
            >
                <Input type={"date"} />
            </Form.Item>
            <Form.Item
                name="status"
                hidden
            >
                <Input type="hidden" />
            </Form.Item>
        </ModalComponent>
    );
};

export default UpdateProjectModal;